<template>
  <a-drawer width="65%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('活动记录.活动类型')" prop="activityType" >
        <a-select :placeholder="$t('通用.输入.请选择')+$t('活动记录.活动类型')" v-model="form.activityType" allowClear>
          <a-select-option v-for="(item, index) in activityTypeList" :value="item.id"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.活动名称')" prop="activityName" >
        <a-input v-model="form.activityName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('活动记录.活动名称')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.分享文案')" prop="posterTxt" >
        <a-input v-model="form.posterTxt" :placeholder="$t('通用.输入.请输入')+$t('活动记录.分享文案')" />
      </a-form-model-item>
      <a-form-item :label="this.$t('通用.文本.多语言配置')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <a-table :data-source="form.localeList" :columns="localeColumns" rowKey="id" :pagination="false" bordered>
          <span slot="locale" slot-scope="text, record">
           <custom-dict-tag :options="customDict.LocaleEnum" :value="record.locale"/>
          </span>
          <div slot="activityName" slot-scope="text, record" >
            <a-input v-model="record.activityName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('活动记录.活动名称')" @blur.native.capture="validatorLocaleName"   />
          </div>
          <div slot="posterTxt" slot-scope="text, record" >
            <a-input v-model="record.posterTxt" :placeholder="$t('通用.输入.请输入')+$t('活动记录.分享文案')"  />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-button type="primary" @click="getTranslation(record)">
              {{$t('通用.按钮.翻译')}}
            </a-button>
          </div>
        </a-table>
        <span style="color: red;">{{ errorMessage }}</span>
      </a-form-item>
      <a-form-model-item :label="$t('活动记录.是否展示到日历')" prop="isShowCalendar" >
        <a-radio-group v-model="form.isShowCalendar"  button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.商品最大购买数量')" prop="maxBuyNum" >
        <a-input v-model="form.maxBuyNum"  @change="limitMaxBuyNum()"
                 :placeholder="$t('通用.输入.请输入')+$t('商品.虚拟销量')"/>
      </a-form-model-item>

      <a-form-model-item :label="$t('活动记录.开始时间')" prop="startTime" >
        <a-date-picker style="width: 100%" v-model="form.startTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.结束时间')" prop="endTime" >
        <a-date-picker style="width: 100%" v-model="form.endTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.活动封面图')" prop="coverPic" >
        <a-upload
          name="coverPic"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.coverPic"
            :src="form.coverPic"
            alt="picture"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 351 x 175</span>
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.分享海报图')" prop="posterPic" >
        <a-upload
          name="posterPic"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverDetailImgUpload">
          <img
            v-if="form.posterPic"
            :src="form.posterPic"
            alt="posterPic"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 351 x 175</span>
      </a-form-model-item>

<!--      <a-form-model-item :label="$t('活动记录.最多参与次数')" prop="limitNum" >
        <a-input-number style="width: 100%" v-model="form.limitNum" @change="limitMaxNum"  :placeholder="$t('通用.输入.请输入')+$t('活动记录.最多参与次数')" />
      </a-form-model-item>-->
      <a-form-model-item :label="$t('通用.文本.排序')" prop="sort" >
        <a-input-number style="width: 100%" v-model="form.sort" @change="limitSort" :placeholder="$t('通用.输入.请输入')+$t('通用.文本.排序')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('活动记录.是否上架')" prop="status" class="margin-bottom-10">
        <a-radio-group v-model="form.status"  button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.ActivitySaleStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('通用.文本.选择商品')" >
        <div @click="openProdSelect" style="width: 100px">
          <a-button type="dashed" @click="openProdSelect" danger>
            <span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>
          </a-button>
        </div>
      </a-form-model-item>
      <a-table v-if="this.form.goodsList.length>0"
               :loading="goodsLoading"
               :scroll="{ x: '130%' }"
               rowKey="id"
               :size="tableSize"
               :columns="goodsColumns"
               :data-source="form.goodsList"
               :pagination="false"
               :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>

        <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
        </span>
        <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleGoodsDelete(record.id)">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>


      <a-form-model-item :label="$t('活动记录.活动详情')" prop="description" >
        <editor v-model="form.description" :placeholder="$t('通用.输入.请输入')+$t('活动记录.活动详情')"/>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <checkbox-goods-select-form ref="CheckboxGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></checkbox-goods-select-form>
  </a-drawer>
</template>

<script>
import { getActivity, addActivity, updateActivity ,getActivityRank} from '@/api/activity/activity'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateRdStr, uploadObject, uploadOss} from "@/api/tool/oss";
import {allBrand} from "@/api/goods/brand";
import {listCategory} from "@/api/goods/category";
import Editor from "@/components/Editor/Editor";
import {translation} from "@/api/tool/common";
import CheckboxGoodsSelectForm from "@/views/goods/goods/modules/CheckboxSelectForm";
import { tableMixin } from '@/store/table-mixin'
import {listType} from "@/api/activity/type";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    CheckboxGoodsSelectForm,
    CustomDictTag,
  },
  mixins: [tableMixin],
  data () {
    return {
      goodsLoading: false,
      goodsColumns: [
        {
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 200,
          fixed: 'left',
        },
        {
          title: this.$t('商品.包装单位'),
          width: 60,
          dataIndex: 'unit',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.售卖开始时间'),
          width: 100,
          dataIndex: 'saleStartTime',
        },
        {
          title: this.$t('商品.售卖结束时间'),
          width: 100,
          dataIndex: 'saleEndTime',
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
        {
          title: this.$t('商品.商品状态'),
          dataIndex: 'status',
          width: 60,
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('商品.创建时间'),
          dataIndex: 'createTime',
          width: 100,
          scopedSlots: {customRender: 'createTime'},
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          fixed: 'right',
          width: 70,
          scopedSlots: {customRender: 'operation'},
        }
      ],
      errorMessage:undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: { customRender: 'locale' }
        }, {
          title: this.$t('活动记录.活动名称'),
          dataIndex: 'activityName',
          scopedSlots: { customRender: 'activityName' },
          key: 'activityName'
        }, {
          title: this.$t('活动记录.分享文案'),
          dataIndex: 'posterTxt',
          scopedSlots: { customRender: 'posterTxt' },
          key: 'posterTxt'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          width: 20,
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      activityTypeList:[],
      categoryList: [{id: "0", categoryName: ''}],
      cateOptions: [],
      brandList: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,
        localeList: [],
        activityType: null,
        goodsList:[],
        maxBuyNum:6,
        activityName: null,
        isShowCalendar:true,
        startTime: null,

        endTime: null,

        coverPic: null,

        posterPic: null,

        posterTxt: null,

        levelIds: null,

        limitNum: null,

        description: null,

        sort: null,
        status: null,
        area: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        activityType: [
          { required: true, message: this.$t('活动记录.活动类型 1线上签售')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        activityName: [
          { required: true, message: this.$t('活动记录.活动名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        posterTxt: [
          { required: true, message: this.$t('活动记录.分享文案')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: this.$t('活动记录.开始时间')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        endTime: [
          { required: true, message: this.$t('活动记录.结束时间')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        coverPic: [
          { required: true, message: this.$t('活动记录.活动封面图')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        limitNum: [
          { required: true, message: this.$t('活动记录.最多参与次数')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        status: [
          { required: true, message: this.$t('活动记录.是否上架')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        sort: [
          { required: true, message: this.$t('活动记录.排序')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getAtvCateList();
    this.getBrandList();
    this.getCateList();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        localeList: [],
        goodsList:[],
        activityType: null,
        activityName: null,
        maxBuyNum:6,
        isShowCalendar:true,
        startTime: null,
        endTime: null,
        coverPic: null,
        posterPic: null,
        status: true,
        posterTxt: null,
        levelIds: null,
        limitNum: null,
        description: null,
        sort: null,
        area: null,
        createTime: null,
        remark: null,
      }

    },
    limitMaxNum(sort) {
      let val = sort;
      if(val==null||val==undefined||val==''|| val<0){
        val=0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.limitNum = val
    },
    handleGoodsDelete(key) {
      const dataSource = [...this.form.goodsList];
      this.form.goodsList = dataSource.filter(item => item.id !== key);
    },
    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.activityName && !this.form.posterTxt) {
        return;
      }
      let data = {"text": this.form.activityName, "locale": record.locale, "time": generateRdStr()};
      translation(data).then(response => {
        record.activityName = response.data
      })
      let data2 = {"text": this.form.posterTxt, "locale": record.locale, "time": generateRdStr()};
      translation(data2).then(response => {
        record.posterTxt = response.data
      })
    },
    limitSort(sort) {
      let val = sort;
      if(val==null||val==undefined||val==''|| val<0){
        val=0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.sort = val
    },

    limitMaxBuyNum() {
      // console.log(i);
      let val = this.form.maxBuyNum.replace(/^\D*([0-9]\d*)?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.maxBuyNum = val
    },

    getAtvCateList() {
      listType({status: 1}).then(response => {
        this.activityTypeList = response.data;
      })
    },

    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },

    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    indexGoodsModalSelect(records) {
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    openProdSelect() {
      this.$refs.CheckboxGoodsSelectForm.openGoods(undefined, this.form.goodsList, 10);
    },


    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'activity'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'coverPic', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    coverDetailImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'activity'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'posterPic', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束

    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.initLocale()
      this.formTitle = this.$t('通用.文本.添加')
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e=>{
        if (!e.activityName) {
          let localeName = localeEnum.find(locale=>locale.type===e.locale).name;
          errorMessage += localeName+this.$t('活动记录.活动名称') + this.$t('通用.文本.不能为空')+';';
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage = "";
      return true;
    },
    initLocale() {
      //初始化多语言
      let localeList = [];
      let i = 1 ;
      this.customDict.LocaleEnum.forEach(e=>{
        localeList.push({"id":i ,"locale":e.type,'posterTxt':null,"activityName":null })
        i++;
      })
      this.form.localeList = localeList;
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getActivity({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      let localeValid = this.validatorLocaleName();
      if (!localeValid) {
        return false;
      }
      if (this.form.goodsList.length === 0) {
        this.$message.error('请选择商品', 3)
        return false;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateActivity(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addActivity(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      });
    }
  }
}
</script>
